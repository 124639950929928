@media only screen and (min-height: 500px) {
    .darkBG-success {
        background-color: rgba(0, 0, 0, 0.2);
        width: 100vw;
        height: 100vh;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
    
    .centered-success {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .modal-success {
        width: 500px;
        height: 280px;
        background: white;
        color: white;
        z-index: 100;
        border-radius: 16px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transition: ease-in-out all 1s;
    }
    
    .modalHeader-success {
        height: 50px;
        background: white;
        overflow: hidden;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
    
    .heading-success {
        margin: 0;
        padding: 10px;
        color: black;
        font-weight: 500;
        font-size: 30px;
        text-align: center;
    }
    
    .modalContent-success {
        padding: 10px;
        margin-top:10px;
        font-size: 18px;
        color: #2c3e50;
        text-align: center;
    }
    
    .modalActions-success {
        position: absolute;
        bottom: 2px;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .actionsContainer-success {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    .closeBtn-success {
        cursor: pointer;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 8px;
        border: none;
        font-size: 18px;
        color: #2c3e50;
        background: white;
        transition: all 0.25s ease;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        align-self: flex-end;
        margin-top: -7px;
        margin-right: -7px;
    }
    
    .closeBtn-success:hover {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transform: translate(-4px, 4px);
    }
    
    .cancelBtn-success {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        padding: 11px 28px;
        border-radius: 12px;
        font-size: 0.8rem;
        border: none;
        color: #2c3e50;
        background: #fcfcfc;
        transition: all 0.25s ease;
    }
    
    .cancelBtn-success:hover {
        box-shadow: none;
        transform: none;
        background: whitesmoke;
    }

    .successBtn {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        padding: 11px 28px;
        border-radius: 12px;
        font-size: 18px;
        border: none;
        color: #fff;
        background: #03ac13;
        transition: all 0.25s ease;
    }
    
    .successBtn:hover {
        box-shadow: 0 10px 20px -10px #03ac13;
        transform: translateY(-5px);
        background: #03ac13;
    }

    img {
        width: 100px;
    }
}
@media only screen and (max-height: 500px) {
    .darkBG-success {
        background-color: rgba(0, 0, 0, 0.2);
        width: 100vw;
        height: 100vh;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
    
    .centered-success {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .modal-success {
        width: 500px;
        height: 280px;
        background: white;
        color: white;
        z-index: 100;
        border-radius: 16px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transition: ease-in-out all 1s;
    }
    
    .modalHeader-success {
        height: 50px;
        background: white;
        overflow: hidden;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
    
    .heading-success {
        margin: 0;
        padding: 10px;
        color: black;
        font-weight: 500;
        font-size: 30px;
        text-align: center;
    }
    
    .modalContent-success {
        padding: 10px;
        margin-top:10px;
        font-size: 18px;
        color: #2c3e50;
        text-align: center;
    }
    
    .modalActions-success {
        position: absolute;
        bottom: 2px;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .actionsContainer-success {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    .closeBtn-success {
        cursor: pointer;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 8px;
        border: none;
        font-size: 18px;
        color: #2c3e50;
        background: white;
        transition: all 0.25s ease;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        align-self: flex-end;
        margin-top: -7px;
        margin-right: -7px;
    }
    
    .closeBtn-success:hover {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transform: translate(-4px, 4px);
    }
    
    .cancelBtn-success {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        padding: 11px 28px;
        border-radius: 12px;
        font-size: 0.8rem;
        border: none;
        color: #2c3e50;
        background: #fcfcfc;
        transition: all 0.25s ease;
    }
    
    .cancelBtn-success:hover {
        box-shadow: none;
        transform: none;
        background: whitesmoke;
    }

    .successBtn {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        padding: 11px 28px;
        border-radius: 12px;
        font-size: 18px;
        border: none;
        color: #fff;
        background: #03ac13;
        transition: all 0.25s ease;
    }
    
    .successBtn:hover {
        box-shadow: 0 10px 20px -10px #03ac13;
        transform: translateY(-5px);
        background: #03ac13;
    }

    img {
        width: 100px;
    }
}
