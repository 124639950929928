@media only screen and (min-width: 650px) {
    .skill-container {
        .text-zone {
            width: 90%;
            animation: fadeIn 1s 1s;
            padding-bottom: 80px;
        }

        .grid-container {
            display: grid;
            gap: 10px 5px;
            grid-template-columns: auto auto auto auto auto;
        }

        .grid-item {
            text-align: center;

            div {
                color: white;
                margin-top: 0.5px;
                // max-width: 50px;
                font-size: 20px;
                font-family: sans-serif;
            }

            img {
                width:120px;
                transition: all .2s ease-in-out;
            }

            img:hover {
                transition: all .2s ease-in-out;
                transform: scale(1.2);
            }
        }

        .button4{
            display:inline-block;
            padding:0.3em 0.3em;
            margin:0 0.1em 0.1em 0;
            border:0.16em solid rgba(255,255,255,0);
            border-radius:2em;
            box-sizing: border-box;
            text-decoration:none;
            font-family:sans-serif;
            font-weight:300;
            color:#FFFFFF;
            text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
            text-align:center;
            transition: all 0.2s;
        }

        .button4:hover{
            border-color: rgba(255,255,255,1);
        }

        .frontend {
            background-color: #03ac13;
        }

        .backend {
            background-color: #f14e4e;
        }

        .general {
            background-color: #4e9af1;
        }

        .data {
            background-color: #9a4ef1;
        }

        .ar {
            background-color: #ff5722;
        }
    }
}

@media only screen and (max-width:650px) {
    .skill-container {
        .text-zone {
            top:30%;
            width: 90%;
            animation: fadeIn 1s 1s;
            padding-bottom:20%;
        }

        .grid-container {
            display: grid;
            gap: 10px 5px;
            grid-template-columns: auto auto auto auto;
        }

        .grid-item {
            text-align: center;

            div {
                color: white;
                margin-top: 0.5px;
                // max-width: 50px;
                font-size: 15px;
                font-family: sans-serif;
            }

            img {
                width:90px;
                transition: all .2s ease-in-out;

            }

            img:hover {
                transition: all .2s ease-in-out;
                transform: scale(1.2)
            }
        }

        .button4{
            display:inline-block;
            padding:0.3em 0.3em;
            margin:0 0.1em 0.1em 0;
            border:0.16em solid rgba(255,255,255,0);
            border-radius:2em;
            box-sizing: border-box;
            text-decoration:none;
            font-family:sans-serif;
            font-weight:300;
            color:#FFFFFF;
            text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
            text-align:center;
            transition: all 0.2s;
        }

        .button4:hover{
            border-color: rgba(255,255,255,1);
        }

        .frontend {
            background-color: #03ac13;
        }

        .backend {
            background-color: #f14e4e;
        }

        .general {
            background-color: #4e9af1;
        }

        .data {
            background-color: #9a4ef1;
        }
        
        .ar {
            background-color: #ff5722;
        }
    }
}
