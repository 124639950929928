@media only screen and (min-width: 650px) {
    .about-page {
        text-align: left;
        .text-zone {
            position: absolute;
            // top: 180px;
            // padding: 5%;
            left: 5%;
            transform: translateY(-20px);
            width: 50%;
            vertical-align: middle;
            display: table-cell;
            // max-height: 90%;
            // overflow-y: scroll;
        }

        h1 {
            font-size: 6vw;
            font-family: 'Coolvetica';
            color:#0082FF;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;
            animation: pulse 1s 2s;
        }

        .about-img {
            width: 50%;
            img {
                position: absolute;
                width: 35%;
                top: auto;
                margin-left: 0;
                right: 5%;
                overflow: hidden;
                transition: all .2s ease-in-out;
            }
            img:hover {
                transition: all .2s ease-in-out;
                transform: scale(1.1);
            }
        }

        p {
            font-size: 18px;
            color:#fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
            line-height: 25px;

            &:nth-of-type(1) {
                animation-delay:2.4s;
            }
            &:nth-of-type(2) {
                animation-delay:2.6s;
            }
            &:nth-of-type(3) {
                animation-delay:2.8s;
            }
            &:nth-of-type(4) {
                animation-delay:3s;
            }

            a {
                color:#ffd700;
                text-decoration: none;
                border: 0.01px solid #ffd700;
                text-align: center;
                padding: 2px;
                transition: all .5s ease-in-out;

            }

            a:hover {
                background-color: rgba(255, 215, 0, 0.1);
                transition: all 0.5s ease-in-out;
                cursor: pointer;
            }
        }
    }

    .skills-page {
        position: relative;
        top:600px;
        height:100%;
    }
}

@media only screen and (max-width: 650px) {
    .about-page {
        text-align: center;
        .text-zone {
            position: absolute;
            // top: 100px;
            left: 5%;
            transform: translateY(-30px);
            width: 90%;
        }

        h1 {
            font-size: 4vw;
            font-family: 'Coolvetica';
            color:#0082FF;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;
            animation: pulse 1s 2s;
        }

        .about-img {
            height: 0;
            visibility: hidden;
        }

        p {
            font-size: 15px;
            color:#fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
            line-height: 20px;

            &:nth-of-type(1) {
                animation-delay:2.4s;
            }
            &:nth-of-type(2) {
                animation-delay:2.6s;
            }
            &:nth-of-type(3) {
                animation-delay:2.8s;
            }

            a {
                font-size: 13px;

                color:#ffd700;
                text-decoration: none;
                border: 0.01px solid #ffd700;
                text-align: center;
                padding: 2px;
                transition: all .5s ease-in-out;

            }

            a:hover {
                background-color: rgba(255, 215, 0, 0.1);
                cursor: pointer;
                transition: all .5s ease-in-out;

            }
        }
    }

    .skills-page {
        position: relative;
        top:420px;
        // height: 110vh;
    }
}