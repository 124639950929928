.nav-bar {
    background: #181818;
    width: 100%;
    height: 90px;
    position: absolute;
    top: 0;
    z-index: 3;
    min-width: 500px;
    // animation: fadeIn 1s 3s backwards;

    .logo {
        display: block;
        padding: 0 0;

        img {
            display: block;
            margin: auto 16px;
            width: 90px;
            height: auto;
        }
    }

    button {
        position: fixed;
        font-size: 25px;
        right: 40px;
        top: 23px;
        z-index: 10;
        background: transparent;;
        border: none;
        cursor: pointer;
        display: none;
        color:#4d4d4e;
    }

    button:hover {
        color: #ffd700
    }
}

nav {
    display: block;
    text-align: center;
    position: absolute;
    left: 15%;
    top: 20%;
    height: 100%;

    a {
        font-size:45px;
        color: #4d4d4e;
        display: inline-block;
        line-height: 51px;
        width: 16vw;
        position: relative;
        text-decoration: none;

        i {
            transition: all 0.3s ease-out;
        }

        &:hover {
            color:#ffd700;
            
            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &:after {
            content: '';
            font-size: 15px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;
        }

        &:first-child {
            &::after {
                content:'HOME';
            }
        }
    }

    a.about-link {
        &:after {
            content: 'ABOUT';
        }
    }

    a.experience-link {
        &:after {
            content: 'EXPERIENCE';
        }
    }

    a.projects-link {
        &:after {
            content: 'PROJECTS';
        }
    }

    a.contact-link {
        &:after {
            content: 'CONTACT';
        }
    }

    a.active {
        svg {
            color:#ffd700;
        }
    }

    .menuNav {
        overflow-y: scroll;
        list-style: none;
        position: fixed;
        top: 0;
        background: #181818;
        right: 0;
        bottom: 0;
        height: 100vh;
        width: 0;
        overflow: hidden;
        visibility:hidden;

        max-width: 100px;
        z-index: 9;
    }
      
    .menuNav.showMenu {
        width: -10;
        visibility:hidden;


        li {
            a {
                text-align: right;
                color: #181818;
                position: relative;
                font-size: 1.4rem;
                right: 40px;
                top: 48px;
                z-index: 0;

                &:hover {
                    color:#ffd700;
                }

                &:first-child {
                    &::after {
                        content:'';
                    }
                }
            }

            a.active {
                svg {
                    color:#ffd700;
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .nav-bar {
        background: #181818;
        width: 100%;
        height: 90px;
        position: absolute;
        top: 0;
        z-index: 3;
        min-width: 100px;
        
        button {
            position: fixed;
            font-size: 45px;
            right: 20px;
            top: 12px;
            z-index: 10;
            background: transparent;
            border: none;
            cursor: pointer;
            display: block;
        }

        button:hover {
            color: #ffd700;
        }
    }

    nav {
        a {
            display: none;
        }

        .menuNav {
            list-style: none;
            position: fixed;
            top: 0;
            background: #181818;
            right: 0;
            bottom: 0;
            height: 100vh;
            width: 0;
            visibility:hidden;
            overflow: hidden;
            max-width: 100px;
            z-index: 9;
        }
          
        .menuNav.showMenu {
            width: 100%;
            visibility: visible;
            transition: all 2s ease-out;  
            background: #181818;    

            li {
                a {
                    color: white;
                    position: relative;
                    font-size: 1.8rem;
                    // right: 40px;
                    top: 48px;
                    margin-left: 20px;
                    z-index: 10;
                    display: flex;
    
                    &:hover {
                        color:#ffd700;
                    }
    
                    &:first-child {
                        &::after {
                            content:'';
                        }
                    }
                }
            }
        }
    }
}
