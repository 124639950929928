@media only screen and (min-width: 650px) {
    .text-animate {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate:hover {
        color:#ffd700;
    }
    
    @for $i from 15 through 35 {
        .text-animate._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            font-size: 9vw;
    
            &:hover {
                color: #ffd700;
            }
        }
    }

    .text-animate2 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate2:hover {
        color:#ffd700;
    }

    @for $i from 15 through 35 {
        .text-animate2._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            font-size: 100px;
    
            &:hover {
                color: #ffd700;
            }
        }
    }

    .text-animate3 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate3:hover {
        color:#ffd700;
    }

    @for $i from 15 through 35 {
        .text-animate3._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            font-size: 7vw;
    
            &:hover {
                color: #ffd700;
            }
        }
    }

    .text-animate4 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }

    .text-animate5 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate5:hover {
        color:#ffd700;
    }

    @for $i from 15 through 35 {
        .text-animate5._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            // font-size: 82px;
            font-size: 8vw;
    
            &:hover {
                color: #ffd700;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .text-animate {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate:hover {
        color:#ffd700;
    }
    
    @for $i from 15 through 28 {
        .text-animate._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:hsl(209, 100%, 50%);
            font-size: 12vw;
    
            &:hover {
                color: #ffd700;
            }
        }
    }


    .text-animate2 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate2:hover {
        color:#ffd700;
    }

    @for $i from 15 through 35 {
        .text-animate2._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            font-size: 70px;
    
            &:hover {
                color: #ffd700;
            }
        }
    }

    .text-animate3 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate3:hover {
        color:#ffd700;
    }

    @for $i from 15 through 35 {
        .text-animate3._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            font-size: 50px;
    
            &:hover {
                color: #ffd700;
            }
        }
    }

    .text-animate5 {
        display: inline-block;
        opacity: 0;
        animation: fadeIn 1s 1s;
        animation-fill-mode: forwards;
        min-width: 10px;
    }
    
    .text-animate5:hover {
        color:#ffd700;
    }

    @for $i from 15 through 35 {
        .text-animate5._#{$i} {
            animation-delay: #{calc($i / 10)}s;
            // text-shadow: 1px 1px #ffd700;
            color:#0082FF;
            font-size: 65px;
    
            &:hover {
                color: #ffd700;
            }
        }
    }
}

