.page {
    width: 100%;
    height: 100vh;
    top: 60px;
    overflow-y: scroll;
    position: absolute;
}

.container {
    width: -webkit-fill-available;
    width: 100%;
    will-change: contents;
    height: 85%;
    // min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 15%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 0.5s;
}

@media only screen and (max-width: 350px) {
    .page {
        width: 100%;
        height: 100vh;
        top: 60px;
        overflow-y: scroll;
        position: absolute;
    }
    
    .container {
        width: -webkit-fill-available;
        width: 100%;
        will-change: contents;
        height: 85%;
        // min-height: 566px;
        position: absolute;
        opacity: 0;
        top: 15%;
        margin: 0 auto;
        z-index: 1;
        transform-style: preserve-3d;
        animation: fadeIn 1s forwards;
        animation-delay: 0.5s;
    }
}
