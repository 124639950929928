@media only screen and (min-width: 650px) {
    .work-page {
        text-align: center;
        border-right: 0.5px solid #ffd700;

        .text-zone {
            transform: translateY(0%);
        }

        h1 {
            color: white;
            margin: 0;
            font-size: 45px;
            font-family: 'Coolvetica';
            font-weight: 400;
            animation: pulse 1s 3.2s;
            padding-bottom: 1%;
        }

        ul {
            text-align: center;
            padding-inline-start: 0px;
        }



        li {
            text-align: center;
            font-size:30px;
            margin-top: 0;
            font-weight: 900;
            list-style: none;
            animation: pulse 1s 3.4s;


            .job-name {
                width: 100%;
                padding: 10px;
                color: #ffd700;
                border: 1px solid #ffd700;
                text-decoration: none;
                transition: all 0.5s ease;
            }

            .job-name:hover {
                width: 100%;
                padding: 10px;
                color: white;
                border: 1px solid #ffd700;
                background-color: #ffd700;
                text-decoration: none;
                transition: all 1s ease;
            }

            .job-img {
                width:150px;
                margin-bottom: -20px;
                transition: all .3s ease-in-out;
            }

            .job-img:hover {
                transition: all .3s ease-in-out;
                transform: scale(1.1);
            }

            .job-title {
                margin-top:25px;
                font-weight: 800;
            }

            .job-date {
                margin-top: 10px;
                color: #0082FF;
            }
            .job-description {
                margin-top: 10px;
                margin-bottom: 20px;
                padding-left: 30px;
                padding-right: 30px;
                font-weight: 300;
                color: white;
                font-size: 20px;
            }
            &:nth-of-type(3) {
                padding-bottom:20%;
            }
        }

    }
}

@media only screen and (max-width: 650px) {
    .work-page {
        text-align: center;

        .text-zone {
            transform: translateY(0%);
        }

        h1 {
            color: white;
            margin: 0;
            font-size: 45px;
            font-family: 'Coolvetica';
            font-weight: 400;
            animation: pulse 1s 3.2s;
            padding-bottom: 1%;
        }

        ul {
            text-align: center;
            padding-inline-start: 0px;
        }

        li {
            text-align: center;
            font-size:20px;
            margin-top: 0;
            font-weight: 900;
            list-style: none;
            animation: pulse 1s 3.4s;


            .job-name {
                width: 100%;
                padding: 10px;
                color: #ffd700;
                border: 1px solid #ffd700;
                text-decoration: none;
                transition: all 0.5s ease;
                font-size: 30px;
            }

            .job-name:hover {
                width: 100%;
                padding: 10px;
                color: white;
                border: 1px solid #ffd700;
                background-color: #ffd700;
                text-decoration: none;
                transition: all 1s ease;
                font-size: 30px;
            }

            .job-img {
                width:150px;
                transition: all .3s ease-in-out;
            }

            .job-img:hover {
                transition: all .3s ease-in-out;
                transform: scale(1.1);
            }

            .job-title {
                color:white;
                margin-top:25px;
                font-weight: 800;
            }

            .job-date {
                margin-top: -20px;
                color: #0082FF;
                font-weight: 300;

            }
            .job-description {
                margin-top: -20px;
                margin-bottom: 20px;
                padding-left: 30px;
                padding-right: 30px;
                font-weight: 300;
                color: white;
                font-size: 20px;

            }
            &:nth-of-type(3) {
                padding-bottom:10%;
            }
        }
    }
}